// index.tsx
import ReactDOM from "react-dom/client";
import { FronteggProvider } from "@frontegg/react";
import "./index.css";
import React, { lazy } from "react";

const contextOptions = {
  baseUrl: process.env.REACT_APP_FRONTEGG_DOMAIN as string,
  clientId: process.env.REACT_APP_FRONTEGG_CLIENT_ID as string,
  appId: process.env.REACT_APP_FRONTEGG_APP_ID as string,
  cacheOptions: {
    timeToLive: 1000 * 60 * 60, // Cache for 1 hour
    enabled: true,
  },
};

// Optionally, you can add a runtime check to ensure these values are set
if (
  !contextOptions.baseUrl ||
  !contextOptions.clientId ||
  !contextOptions.appId
) {
  throw new Error(
    "Missing required Frontegg configuration. Check your environment variables."
  );
}

const authOptions = {
  keepSessionAlive: true,
  requestCredentials: "include",
  cacheTokens: true,
  preloadTokens: true,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Wrap App component with React.lazy and Suspense
const LazyApp = lazy(() => import("./App"));

root.render(
  <React.StrictMode>
    <FronteggProvider
      contextOptions={contextOptions}
      hostedLoginBox={true}
      authOptions={authOptions}
    >
      <React.Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            Loading...
          </div>
        }
      >
        <LazyApp />
      </React.Suspense>
    </FronteggProvider>
  </React.StrictMode>
);
